<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="11" class="mx-auto h-100" v-if="pageLoaded">
      <div class="h4 mb-4 ms-5">
        <span class="h3 grey--text text--darken-1">
          <v-icon class="primary--text">mdi-comment-alert-outline</v-icon>
          پزشک گرامی در صورت تغییر شیفت، صفحه خود را بروزرسانی (Refresh) کنید.
        </span>
      </div>
      <v-card class="cards h-100 pa-8">
        <v-card-title>
          <v-row>
            <v-col class="pa-0">
              <v-row style="justify-content: flex-end">
                <div class="date-div">
                  <span class="span-1"> ساعت </span>
                  <span class="span-2">
                    {{ time }}
                  </span>
                </div>
                <div class="date-div ms-3 me-3">
                  <span class="span-1"> روز </span>
                  <span class="span-2">
                    {{ splitDate(currentDate).day }}
                  </span>
                </div>

                <div class="date-div">
                  <span class="span-1"> ماه </span>
                  <span class="span-2">
                    {{ splitDate(currentDate).month }}
                  </span>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="mt-8">
          <h5 class="h1 ps-2">دکتر {{ drName }} گرامی،</h5>
          <h5 style="color: #656a81" class="mt-3 ps-2">
            به سامانه مدیریت درمان توتیاطب خوش آمدید!
          </h5>
        </div>

        <v-btn
          class="me-auto primary-btn px-7 mt-5"
          to="/doctor/visitsList"
          target="_blank"
          height="52"
        >
          <v-icon class="pe-1">mdi-script-text-outline</v-icon>
          <span>ویزیت‌های من</span></v-btn
        >
        <v-row>
          <v-col></v-col>
          <v-col>
            <div>
              <v-img
                v-if="isDentist == 'true'"
                src="@/assets/images/dentist.jpg"
                width="800"
              ></v-img>
              <v-img
                v-else
                src="@/assets/images/clinic-2.jpg"
                width="800"
              ></v-img></div
          ></v-col>
        </v-row>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
export default {
  data() {
    return {
      role: "",
      time: "",
      moment: moment,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      persianMonths: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      reactivity: false,
      shiftDoctor: "",
      drName: "",
      pageLoaded: false,
      isDentist: "",
    };
  },
  methods: {
    getDashboardInfo() {
      this.vLoading = true;
      if (this.isDentist == "true") {
        this.$http
          .post(
            this.baseUrl + "/clinic/dentistry/medicalTeam/dashboard/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("role", res.data.role);
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.drName = res.data.name;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
              this.pageLoaded = true;
              this.vLoading = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.vLoading = false;
            this.toast("خطا: " + err.data, "error");
          });
      } else {
        this.$http
          .post(
            this.baseUrl + "/clinic/medicalTeam/dashbaord/getInfo",
            {},
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem("role", res.data.role);
              localStorage.setItem("shiftDoctor", res.data.shiftDoctor);
              this.shiftDoctor = res.data.shiftDoctor;
              this.drName = res.data.name;
              this.$emit("doctorDashboard", res.data.shiftDoctor);
              this.pageLoaded = true;
              this.vLoading = false;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.vLoading = false;
            this.toast("خطا: " + err.data, "error");
          });
      }
    },
    splitDate(Date) {
      var dateparts = Date.split("-");
      var year = dateparts[0];
      var day = dateparts[2];
      var month = this.persianMonths[parseInt(dateparts[1] - 1)];
      return {
        day: day,
        month: month,
        year: year,
      };
    },
    getTime() {
      var now = new Date().toLocaleTimeString("fa-IR");
      now.split(":");
      now = now.substring(0, now.length - 3);
      this.time = now;
      setTimeout(() => {
        this.getTime();
      }, 60000);
    },
  },
  mounted() {
    this.isDentist = localStorage.getItem("isDentist");
    this.getTime();
    this.getDashboardInfo();
  },
};
</script>

<style></style>
